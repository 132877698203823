import React, { useContext, useState, useEffect } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { PRIMARY_COLOR, TERTIARY_COLOR } from "../../../../colors";
import { APIEndpoint } from '../../../../config/apiendpoint_config';
import AppServices from '../../../../services/appServices';
import Swal from 'sweetalert2';
import { BsThreeDots } from "react-icons/bs";
import { UserContext } from "../../../../context/UserContext";

interface Message {
  uuid: string;
  isRead: boolean;
  title: string;
  message: string;
  patientUid: string;
  status: number;
  from: string;
  createdAt: string;
}

interface SecureMessagesProps {
  messagesData: Message[];
  onDeleteMessage: (messageId: string) => void;
  updateIsMessageSelected: () => void;
}

const SecureMessages: React.FC<SecureMessagesProps> = ({ messagesData, onDeleteMessage, updateIsMessageSelected }) => {
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [isDeleteMessageVisible, setIsDeleteMessageVisible] = useState(false);
  const [isRead, setIsRead] = useState<boolean>(false);
  const _appServices = new AppServices();
  const {setMessageCount, messageCount} = useContext(UserContext)

  const handleClick = (message: Message) => {
    setSelectedMessage(message);
    setIsRead(message.isRead);
    getEachMessagesDetails(message)
    getMessageList()
    if (!message.isRead) {
      message.isRead = true; 
    }
  };

  const handleClickMobile = (message: Message) => {
    setSelectedMessage(message);
    setIsRead(message.isRead);
    getEachMessagesDetails(message)
    // getMessageList()
    if (!message.isRead) {
      message.isRead = true; 
    }
    updateIsMessageSelected();
  };

  useEffect(() => {
    console.log("entering into useeffect")
    getMessageList()
  }, [])

  const getMessageList = () => {
    console.log("entering into message")
    _appServices.getData(`${APIEndpoint.postMessages}/all?type=1`).then(res => {
      if (res.status === 200 && res.success) {
        const unreadCount = res.data.filter((item:any )=> (!item.isRead && item.message != 'undefined')).length;
        console.log("unreadCount secure",unreadCount,res.data)
        setMessageCount(unreadCount)
      }
    }).catch(err => {
      console.log('err', err);
    });
  
  }
  const getEachMessagesDetails = (message:any) => {
    _appServices.getData(`${APIEndpoint.postMessages}/${message.uuid}`).then(res => {
      if (res.status === 200 && res.success) {
      
      } else {
        console.error('Error fetching messages:', res.message);
      }
    }).catch(err => {
      console.log('err', err);
    });
  };

  

  const handleBackClick = () => {
    setSelectedMessage(null);
    if (!isRead) {
      setIsRead(true);
    }
    getMessageList()
  };

  const handleBackClickMobile = () => {
    setSelectedMessage(null);
    if (!isRead) {
      setIsRead(true);
    }
    getMessageList()
    updateIsMessageSelected()
  };


  const handleThreeDotsClick = () => {
    setIsDeleteMessageVisible(!isDeleteMessageVisible);
  };

  const handleDelete = () => {
    if (selectedMessage) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          onDeleteMessage(selectedMessage.uuid);
          setSelectedMessage(null);
          getMessageList()
        }
      });
    }
  };

  const formatTimeAgo = (time: string) => {
    const createdAt = new Date(time);
    const now = new Date();
    const timeDiff = now.getTime() - createdAt.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? "a day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
      return "just now";
    }
  };

  return (
    <>
      <div className="profile-form web-view">
      <div className="row">
          {messagesData.length === 0 && (
            <div>
              <p className="text-center">No messages found.</p>
            </div>
          )}
          {!selectedMessage &&
            messagesData.length > 0 &&
            messagesData.map((message:any, index:any) => (
             <div>
               {message.message!='undefined' && <div
                className=""
                key={index}
                style={{cursor:"pointer"}}
                onClick={() => handleClick(message)}
              >
                {/* messages */}
                <div className="row">
                  <div className="col-8">
                    {/* <p className="thick">{message.message}</p> */}
                    <p className="thick" dangerouslySetInnerHTML={{ __html: message.message}}></p>
                  </div>
                  <div className="col-4">
                    <div className="text-end time thick">
                      {formatTimeAgo(message.createdAt)}
                    </div>
                    <div className="text-end read thick">
                      {message.isRead === true ? "Read" : "Unread"}
                    </div>
                  </div>
                </div>
                <hr />
              </div>}
             </div>
            ))}
          {selectedMessage && (
            <div>
              <div className="d-flex justify-content-start align-items-center">
              <div className="row" style={{cursor:"pointer" }} onClick={() => handleBackClick()}>
                <i className="fa-solid fa-angle-left"  style={{fontSize:28, color:PRIMARY_COLOR}}></i>
              </div>

              <div className="row">
                <p className="text-center thick" style={{marginBottom:0,marginLeft:20, color:TERTIARY_COLOR}} >Inbox</p>
              </div>
              </div>

              <div className="inbox-wrapper mt-3">
                <div className="row">
                  <p>From: {selectedMessage.from}</p>
                  <p>{formatTimeAgo(selectedMessage.createdAt)}</p>
                </div>
                <hr />
                <div className="row mt-4">
                  <p>Message : </p>
                  {/* <p>{selectedMessage.message}</p> */}
                  <p className="thick" dangerouslySetInnerHTML={{ __html: selectedMessage.message}}></p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  {isDeleteMessageVisible && (
                    <div
                      style={{
                        paddingLeft: "20px",
                        border: "1px solid grey",
                        borderRadius: "10px",
                        paddingRight: "40px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <p
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: TERTIARY_COLOR,
                        }}
                        onClick={handleDelete}
                      >
                        Delete Message
                      </p>
                    </div>
                  )}
                  <BsThreeDots
                    color="#0095B9"
                    size={30}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={handleThreeDotsClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container mobile-view">
        <div className="row">
          {messagesData.length === 0 && (
            <div>
              <p className="text-center">No messages found.</p>
            </div>
          )}
          {!selectedMessage &&
            messagesData.length > 0 &&
            messagesData.map((message, index) => (
              <div
                className=""
                key={index}
                style={{cursor:"pointer"}}
                onClick={() => handleClickMobile(message)}
              >
                {/* messages */}
                <div className="row">
                  <div className="col-8">
                    {/* <p className="thick">{message.message}</p> */}
                    <p className="thick" dangerouslySetInnerHTML={{ __html: message.message}}></p>
                  </div>
                  <div className="col-4">
                    <div className="text-end time thick">
                      {formatTimeAgo(message.createdAt)}
                    </div>
                    <div className="text-end read thick">
                      {message.isRead === true ? "Read" : "Unread"}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          {selectedMessage && (
            <div>
              <div className="row" style={{cursor:"pointer"}} onClick={() => handleBackClickMobile()}>
                <i className="fa-solid fa-angle-left"></i>
              </div>

              <div className="row">
                <p className="text-center" style={{marginBottom:0,marginLeft:20, color:TERTIARY_COLOR}}>Inbox</p>
              </div>

              <div className="inbox-wrapper mt-3">
                <div className="row">
                  <p>From: {selectedMessage.from}</p>
                  <p>{formatTimeAgo(selectedMessage.createdAt)}</p>
                </div>
                <hr />
                <div className="row mt-4">
                  <p>Message : </p>
                  <p dangerouslySetInnerHTML={{ __html: selectedMessage.message}}></p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  {isDeleteMessageVisible && (
                    <div
                      style={{
                        paddingLeft: "20px",
                        border: "1px solid grey",
                        borderRadius: "10px",
                        paddingRight: "40px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <p
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: TERTIARY_COLOR,
                        }}
                        onClick={handleDelete}
                      >
                        Delete Message
                      </p>
                    </div>
                  )}
                  <BsThreeDots
                    color="#0095B9"
                    size={30}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={handleThreeDotsClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

};

export default SecureMessages;
